import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Container from "@mui/material/Container";

import useConsult from "../Firebase/Hooks/useConsult";

import img7 from "../Images/7.png";
import img7_1 from "../Images/7-1.png";
import Testimonios from "../Components/Testimonios";

const Testimoniales = () => {
  const {
    collection: { testimonios },
  } = useSelector((state) => state.firestore);

  useConsult([
    {
      consulta: "obtenerRealTime",
      collectionName: "testimonios",
    },
  ]);

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta_2" src={img7_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta_2" src={img7} alt="Africare" />
      )}

      <Container
        sx={{ maxWidth: { xs: "lg", sm: "700px", md: "lg", lg: "lg" } }}
      >
        <section>
          <h2 className="titulo-5" style={{ margin: "0", textAlign: "center" }}>
            Testimonios
          </h2>
          <p
            className="texto-2"
            style={{ textAlign: "center", maxWidth: "600px", margin: "auto" }}
          >
            Nos aseguramos de que nuestros pacientes tengan un espacio cómodo y
            seguro mientras recibe la atención médica necesaria.
          </p>

          <div className="contenedor-centro" style={{ margin: "20px" }}>
            {testimonios &&
              testimonios.docs.map((testimonio) => (
                <div className="item-centro">
                  <Testimonios data={testimonio} />
                </div>
              ))}
          </div>
        </section>
      </Container>
    </Fragment>
  );
};

export default Testimoniales;
