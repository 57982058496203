import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

import {
  getDocument,
  getCollection,
  setDocument,
  deleteDocument,
  editDocument,
} from "../Redux/Features/Firestore/firestoreSlice";

import useRealtime from "./useRealtime";
import useStorage from "./useStorage";

/**
 *
 * @param consulta { Obligatorio - Tipo de consulta que se va a ejecutar
 * @param collectionName { Obligatorio - Coleccion a la que se hara la consulta
 * @param condicionWhere { Opcional - condicional where de la consulta
 * @param state { Opcional, excepto (INSERT, UPDATE) -  Datos que se mandan para la consulta (insercion, edicion, etc)
 * @param file { Opcional - Archivo a subir
 * @param files { Opcional - Lista de archivos a subor
 * @param id { Obligatoria para UPDATE - Identificador de archivo a modificar
 * @returns
 */
const useDatabase = ({
  consulta,
  collectionName,
  condicionWhere,
  state,
  file,
  files,
  id,
}) => {
  const { collection } = useSelector((state) => state.firestore);
  const dispatch = useDispatch();
  const [result, setResult] = useState(false);

  // para las consultas en realtime
  const [consultaRealTime, setConsultaRealTime] = useState({
    consulta: "",
  });
  // retorno de las consultas en realtime
  const [realtime] = useRealtime(consultaRealTime);

  // para las consultas en realtime
  const [consultaStorage, setConsultaStorage] = useState({
    consulta: "",
  });
  // retorno de las consultas en realtime
  const [storage] = useStorage(consultaStorage);
  // retorno del estado redux del storage
  const { url } = useSelector((state) => state.storage);

  // obtener documento
  // TODO: agregar condicional para validar que no se haya obtenido el documento anteriormente para efisientisar consultas
  const obtenerDocumento = async () => {
    let res = await dispatch(
      getDocument({
        collectionName: collectionName,
        id: id,
      })
    );

    setResult({ documentData: res.payload });
  };

  // obtener colleccion
  // TODO: agregar condicional para validar que no se haya obtenido la coleccion anteriormente para efisientisar consultas
  const obtenerCollecion = async () => {
    if (!collection[collectionName]) {
      let res = await dispatch(
        getCollection({
          collectionName: collectionName,
          whereCondicion: condicionWhere,
        })
      );

      setResult({ collectionData: res.payload.data });
    }
  };

  // obtener colleccion en tiempo real
  // TODO: mejorar condicional para validar que no se haya obtenido la coleccion anteriormente para efisientisar consultas
  const obtenerRealTime = async () => {
    if (
      !collection[collectionName] ||
      (collection[collectionName].condicionWhere !== condicionWhere &&
        collection[collectionName].condicionWhere !== false)
    ) {
      setConsultaRealTime({
        consulta: "obtenerRealTime",
        collectionName: collectionName,
        condicionWhere: condicionWhere,
      });
    }
  };

  // funcion de retorno de las consultas en realtime
  const obtenerArray = () => {
    if (!realtime.success) {
      Swal.fire({
        title: "Error",
        text: "La informacion no logro ser obtenida",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });

      return setResult([]);
    }

    setResult({ collectionData: realtime });
  };

  // logica para agregar
  const logicaAgregar = () => {
    // evaluamos si esta amndando un archivo y si este archivo aun no se a subido
    if (file && !storage.estado) {
      // priemro: subimos el archivo
      agregarArchivo();
    } else if (files && !storage) {
      // opcion para subir multiples archivos
      // primero: subimos los archivos
      agregarArchivos();
    } else {
      // segundo: agregamos el docuemnto
      agregarDocumento();
    }
  };
  // agregar un archivo
  const agregarArchivo = () => {
    setConsultaStorage({
      consulta: "subirUnArchivo",
      name: state.nombre,
      folder: collectionName,
      file: file,
    });
  };
  // agregar multiples archivos
  const agregarArchivos = () => {
    setConsultaStorage({
      consulta: "subirArchivos",
      name: state.nombre,
      folder: `${collectionName}/${state.nombre}`,
      files,
    });
  };
  // agregar un documento
  const agregarDocumento = async () => {
    // evaluamos si se mando un archivo y si se subio correctamente
    if (file && url !== "") state.url = storage.estado;
    if (files && url !== "") state.imagenes = storage;

    let res = await dispatch(
      setDocument({
        collectionName: collectionName,
        state: state,
      })
    );

    return setResult({ addData: res });
  };

  // logica para actualizar
  // NOTA: se usa ya que hay dos opciones de actualizar el documento, si cuanta o no con un archivo, continua...
  // NOTA: continuacion...si no tiene archivo pregunta si lo quiere actualizar y despues lo actualiza el documento, continua...
  // NOTA: continuacion...si  tiene archivo pregunta si lo quiere actualizar, despues lo elimina el archivo,  continua...
  // NOTA: continuacion.. sube el nuevo archivo y despues actualiza el docuemnto,
  const logicaActualizar = async () => {
    // evaluamos que el documento a actualizar tenga un archivo
    if (state.url && !storage.estado) {
      // cuenta con archivo y lo vamos a eliminar
      eliminarArchivo();
    } else if (!state.url) {
      // no cuenta con archivo, solo lo vamos a actualizar
      actualizarDocumento();
    } else if (storage.tipo === "eliminar" && storage.estado) {
      // cuenta con archivo y ya se elimino, vamos a subir el nuevo archivo
      agregarArchivo();
    } else {
      // cuenta con archivo y ya se elimino, ya se subio el nuevo, vamos a actualizarlo
      actualizarDocumento();
    }
  };
  // actualizar un documento
  const actualizarDocumento = async () => {
    // evaluamos si se mando un archivo y si se subio correctamente
    if (file && url !== "") state.url = storage.estado;

    let res = await dispatch(
      editDocument({
        collectionName: collectionName,
        id: id,
        state: state,
      })
    );

    return setResult({ editData: res });
  };

  // logica para eliminar
  // NOTA: se usa ya que hay dos opciones de eliminar el documento, si cuanta o no con un archivo, continua...
  // NOTA: continuacion...si no tiene archivo pregunta si lo quiere eliminar y despues lo elimina el documento, continua...
  // NOTA: continuacion...si  tiene archivo pregunta si lo quiere eliminar y despues lo elimina el archivo y despues el docuemnto, continua...
  const logicaEliminar = async () => {
    // evaluamos que el documento a eliminar tenga un archivo
    if (state.url && !storage.estado) {
      // cuenta con archivo y lo vamos a eliminar
      eliminarArchivo();
    } else if (!state.url) {
      // no cuenta con archivo
      eliminarDocumento();
    } else {
      // cuenta con archivo y ya se elimino
      eliminarDocumento();
    }
  };
  // eliminar un documento
  const eliminarDocumento = async () => {
    let res = await dispatch(
      deleteDocument({
        collectionName: collectionName,
        id: state.id,
      })
    );

    return setResult({ deleteData: res });
  };

  // eliminar archivo
  const eliminarArchivo = async () => {
    setConsultaStorage({
      consulta: "eliminarUnArchivo",
      urlFile: state.url,
    });
  };

  useEffect(() => {
    console.log("useDatabase");
    if (!realtime) {
      switch (consulta) {
        case "obtenerDocumento":
          obtenerDocumento();
          break;
        case "obtenerCollecion":
          obtenerCollecion();
          break;
        case "obtenerRealTime":
          obtenerRealTime();
          break;
        case "agregarDocumento":
          logicaAgregar();
          break;
        case "actualizarDocumento":
          logicaActualizar();
          break;
        case "eliminarDocumento":
          logicaEliminar();
          break;
        case "reset":
          setConsultaStorage({
            consulta: "",
          });
          break;
        case "resetConsult":
          setResult({
            success: "reset",
          });
          break;
        default:
          console.log("no hizo nada, solo se monto");
          break;
      }
    } else {
      // consulta en realtime
      setConsultaRealTime({ consulta: "reset" });
      obtenerArray();
    }
    // eslint-disable-next-line
  }, [consulta, realtime, storage]);

  return [result];
};

export default useDatabase;
