import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

function Galerias({ images }) {
    return (
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item key={index} xs={4}>
            <Card>
              <CardMedia
                component='img'
                image={image}
                alt='Imagen'
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  export default Galerias;
  