import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

// import { globalContext } from "../../Context/globalContext";

import { setFile, deleteFile } from "../Redux/Features/Storage/storageSlice";

const useStorage = ({ consulta, file, files, name, folder, urlFile }) => {
  // const global = useContext(globalContext);
  // let loader = global.loader;
  // let setLoader = global.setLoader;

  const dispatch = useDispatch();
  const [result, setResult] = useState(false);

  // subir solamente un archivo
  const subirUnArchivo = async () => {
    // if (loader) setLoader(false);

    let res = await dispatch(setFile({ file, name, folder }));

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no logro ser agregado",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setResult({ tipo: "agregar", estado: res.payload });
  };

  // subir multiples archivos
  const subirArchivos = async () => {
    console.log("subir archivos");
    // if (loader) setLoader(false);
    let imagenes = [];

    // subida de imagenes
    await new Promise(async (resolve, reject) => {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];

        let res = await dispatch(setFile({ file, name, folder }));

        if (
          res.payload.status === "failed" ||
          res.meta.requestStatus === "failed"
        ) {
          return Swal.fire({
            title: "Error",
            text: "El campo no logro ser agregado",
            confirmButtonColor: "#2DCD22",
            confirmButtonText: "Continuar",
            icon: "error",
          });
        }

        imagenes.push(res.payload);

        if (index === files.length - 1) resolve(true);
      }
    });

    setResult({ imagenes });
  };

  // Eliminar un archivo
  const eliminarUnArchivo = async () => {
    // if (loader) setLoader(false);

    let res = await dispatch(deleteFile({ urlFile }));

    if (
      res.payload.status === "failed" ||
      res.meta.requestStatus === "failed"
    ) {
      return Swal.fire({
        title: "Error",
        text: "El campo no logro ser agregado",
        confirmButtonColor: "#2DCD22",
        confirmButtonText: "Continuar",
        icon: "error",
      });
    }

    setResult({ tipo: "eliminar", estado: true });
  };

  useEffect(() => {
    switch (consulta) {
      case "subirUnArchivo":
        subirUnArchivo();
        break;
      case "subirArchivos":
        subirArchivos();
        break;
      case "eliminarUnArchivo":
        eliminarUnArchivo();
        break;
      default:
        setResult(false);
        break;
    }
    // eslint-disable-next-line
  }, [consulta]);

  return [result];
};

export default useStorage;
