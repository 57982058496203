import { useEffect, useState } from "react";
import useDatabase from "./useDatabase";

const useConsult = (consultas) => {
  // Recursividad de consultas, para cuando se hacen mas de una consulta en algun componente o vista
  /**
   * DATOS:
   * @param {Obligatorio - Tipo de consulta que se va a ejecutar} consulta
   * @param {Obligatorio - Coleccion a la que se hara la consulta} collectionName
   * @param {Opcional - condicional where de la consulta} condicionWhere
   * @param {Opcional, excepto (INSERT, UPDATE) -  Datos que se mandan para la consulta (insercion, edicion, etc)} state
   * @param {Opcional - Archivo a subir} file
   * @param {Opcional - Lista de archivos a subor} files
   * @param {Obligatoria para UPDATE - Identificador de archivo a modificar} id
   * @returns
   */
  const [indexConsulta, setIndexConsulta] = useState(0);
  const [datosConsulta, setDatosConsulta] = useState({ consulta: false });
  const [result] = useState(null);

  const [res] = useDatabase(datosConsulta);

  // extraccion de datos del array de consultas
  const fetchData = async (index) => {
    console.log("fetchData => ", index);
    const {
      consulta,
      collectionName,
      condicionWhere,
      state,
      file,
      files,
      id,
      urlRedirect,
    } = consultas[index];

    setDatosConsulta({
      consulta,
      collectionName,
      condicionWhere,
      state,
      file,
      files,
      id,
      urlRedirect,
    });

    setIndexConsulta(indexConsulta + 1);
  };

  // se ejecuata cada que se manda una nueva consulta o se actualiza el slices de el storage (cuando se sube una imagen a el storage)
  useEffect(() => {
    console.log("---------------");
    console.log({ consultas });
    console.log("effect consult");
    if (!datosConsulta.consulta && indexConsulta === 0) {
      console.log("primer ciclo");
      fetchData(indexConsulta);
    }
    if (res && indexConsulta < consultas.length) {
      console.log("ciclo => ", indexConsulta);
      fetchData(indexConsulta);
    }
    // eslint-disable-next-line
  }, [consultas, datosConsulta]);
  return [result];
};

export default useConsult;
