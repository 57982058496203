import React from "react";
import { Link } from "react-router-dom";

function createMarkup(descripcion) {
  return { __html: descripcion };
}

function Especialidades({ data }) {
  return (
    <div class="card">
      <img
        src={data.imagenes.imagenes[0]}
        style={{
          height: "75%",
          position: "absolute",
          maxWidth: "80%",
          width: "inherit",
          objectFit: "contain",
        }}
      />
      <div className="icon-container">
        <div>
          <h2 className="titulo-3" style={{ margin: "0" }}>
            {data.nombre}
          </h2>
          <div
            className="texto-tarjeta"
            dangerouslySetInnerHTML={createMarkup(data.descripcion_externa)}
          ></div>
        </div>
        <Link
          className="especialidades-redireccion"
          to={`/servicio/${data.url_redireccion}`}
        >
          Ver más
        </Link>
      </div>
    </div>
  );
}

export default Especialidades;
