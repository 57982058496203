import React from "react";

import { Link } from "react-router-dom";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import ListItemText from "@mui/material/ListItemText";
import logoAfriCare from "../Images/Icons/logo-AfriCare.png";
import instagram from "../Images/Icons/logo-instagram.png";
import facebook from "../Images/Icons/logo-facebook.png";
import whatsapp from "../Images/Icons/logo-whatsapp.png";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="page-footer-borde" />
      <Container maxWidth="lg">
        <Grid container spacing={2} columns={24}>
          <Grid xs={24} md={8} style={{ textAlign: "center" }}>
            <img src={logoAfriCare} alt="" className="img-footer" />
            <br />
            <span className="titulo-footer">Av. Galileo Galilei #4008,</span>
            <br />
            <span className="titulo-footer">Arboledas, Zapopan, Jal.</span>
            <div style={{ marginTop: "20px" }}>
              <span style={{ margin: "0 10px" }}>
                <img src={facebook} alt="" />
              </span>
              <span style={{ margin: "0 10px" }}>
                <img src={instagram} alt="" />
              </span>
              <span style={{ margin: "0 10px" }}>
                <img src={whatsapp} alt="" />
              </span>
            </div>
          </Grid>
          <Grid xs={12} md={4} className="grid-movil">
            <p className="titulo-footer-2">AFRICARE</p>
            <Link to={"/terminos-y-condiciones"}>
              <ListItemText
                primary={"Inicio"}
                className="margin-links-footer"
              />
            </Link>
            <Link to={"/aviso-de-privacidad"}>
              <ListItemText
                primary={"Agenda una cita"}
                className="margin-links-footer"
              />
            </Link>
            <Link to={"/aviso-de-privacidad"}>
              <ListItemText
                primary={"Aviso de privacidad"}
                className="margin-links-footer"
              />
            </Link>
          </Grid>
          <Grid xs={12} md={4} className="grid-movil-2">
            <p className="titulo-footer-2">Especialidades</p>
            <Link to={"/terminos-y-condiciones"}>
              <ListItemText
                primary={"AfriCare"}
                className="margin-links-footer"
              />
            </Link>
            <Link to={"/aviso-de-privacidad"}>
              <ListItemText
                primary={"Servicios"}
                className="margin-links-footer"
              />
            </Link>{" "}
            <Link to={"/aviso-de-privacidad"}>
              <ListItemText
                primary={"Testimonios"}
                className="margin-links-footer"
              />
            </Link>
          </Grid>{" "}
          <Grid xs={12} md={4} className="grid-movil">
            <p className="titulo-footer-2">Proyectos</p>
            <Link to={"/terminos-y-condiciones"}>
              <ListItemText
                primary={"Derivaciones"}
                className="margin-links-footer"
              />
            </Link>
            <Link to={"/aviso-de-privacidad"}>
              <ListItemText
                primary={"Asociaciones"}
                className="margin-links-footer"
              />
            </Link>
          </Grid>
          <Grid xs={12} md={4} className="grid-movil-2">
            <p className="titulo-footer-2">Contacto</p>
            <div className="texto-footer">
              <p style={{ margin: "0px" }} className="margin-links-footer">
                Clínica
              </p>
              <a href="">33 3124 3084</a>
              <br />
              <a href="">33 2386 0821</a>
              <p style={{ margin: "0px" }}>Emergencias</p>
              <a href="">33 1311 4196</a>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="copyright">
        <p>
          Africam© Todos los derechos reservados. Copyright{" "}
          {new Date().getFullYear()}
        </p>
      </div>
      <div className="page-footer-borde" />
    </footer>
  );
};

export default Footer;
