import React, { useState } from "react";
import { Link } from "react-router-dom";
import { headerTheme } from "../Styles/Styles";
import { StyledMenuIcon } from "../Styles/Styles";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import logo from "../Images/logo.png";
import menu from "../Images/Icons/menu.png";

const drawerWidth = 240;
const navItems = [
  { name: "Inicio", url: "/" },
  { name: "Nosotros", url: "/nosotros" },
  { name: "Servicios", url: "/servicios" },
  { name: "Testimonios", url: "/testimonios" },
  { name: "Derivaciones", url: "/derivaciones" },
  { name: "Asociaciones", url: "/asociaciones" },
  { name: "Contacto", url: "/contacto" },
];

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Link to={"/"} style={{ color: "#002F86", textDecoration: "none" }}>
          <img src={logo} alt="Logo" style={{ height: "40px" }} />
        </Link>
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton>
              <Link
                to={item.url}
                style={{ color: "#002F86", textDecoration: "none" }}
              >
                <ListItemText primary={item.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const { pathname } = window.location;

  return (
    <Box sx={{ height: { xs: "90px", sm: "90px", md: "140px", lg: "137px" } }}>
      <CssBaseline />
      <AppBar
        theme={headerTheme}
        component="nav"
        sx={{ height: { xs: "90px", sm: "90px", md: "140px", lg: "137px" } }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "block" },
            }}
          >
            <Link to={"/"} style={{ color: "#002F86", textDecoration: "none" }}>
              <img
                src={logo}
                alt="Logo"
                style={{ width: "70%", maxWidth: "320px" }}
              />
            </Link>
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <img src={menu} alt="" />
          </IconButton>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              gap: "30px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {navItems.map((item) => (
              <Link
                key={item.name}
                style={{
                  color: "#002F86",
                  textDecoration: "none",
                  fontSize: "17px",
                  fontWeight: "600",
                  fontFamily: "Poppins",
                }}
                to={item.url}
              >
                {item.name}
              </Link>
            ))}
            <Link
              key="agenda una cita"
              style={{
                textDecoration: "none",
                fontSize: "17px",
              }}
              className="boton-1"
              to="/contacto"
            >
              ¡Agenda una cita!
            </Link>
          </Box>
        </Toolbar>
        {pathname === "/" && <div className="line-header" />}
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
