import React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import icono1 from "../Images/Icons/1.png";
import icono2 from "../Images/Icons/2.png";
import icono3 from "../Images/Icons/3.png";
import icono4 from "../Images/Icons/4.png";

const Contactos = () => {
  return (
    <Container>
      <Grid container columnSpacing={3} rowSpacing={5}>
        <Grid xs={6} lg={3}>
          <div className="contacto-color-1 contacto-margin">
            <div className="fondo-icono">
              <img src={icono1} alt="" />
            </div>
            <p style={{ marginBottom: "0" }} className="titulo-contacto">
              Teléfono
            </p>
            <p style={{ margin: "0" }}>33 3124 3084</p>
            <p style={{ marginTop: "0" }}>33 2386 0821</p>
          </div>
        </Grid>
        <Grid xs={6} lg={3}>
          <div className="contacto-color-2 contacto-margin">
            <div className="fondo-icono">
              <img src={icono2} alt="" />
            </div>
            <p className="titulo-contacto" style={{ marginBottom: "0" }}>
              Urgencias
            </p>
            <p style={{ margin: "0" }}>33 1311 4196</p>
          </div>
        </Grid>
        <Grid xs={6} lg={3}>
          <div className="contacto-color-2 contacto-margin">
            <div className="fondo-icono">
              <img src={icono3} alt="" />
            </div>
            <p className="titulo-contacto" style={{ marginBottom: "0" }}>
              Dirección
            </p>
            <p style={{ margin: "0" }}>Av. Galileo Galilei #4008,</p>
            <p style={{ marginTop: "0" }}>Arboledas, Zapopan, Jal.</p>
          </div>
        </Grid>
        <Grid xs={6} lg={3}>
          <div className="contacto-color-2 contacto-margin">
            <div className="fondo-icono">
              <img src={icono4} alt="" />
            </div>
            <p className="titulo-contacto" style={{ marginBottom: "0" }}>
              Horario de trabajo
            </p>
            <p style={{ margin: "0" }}>24 horas</p>
            <p style={{ marginTop: "0" }}>365 dias del año</p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contactos;
