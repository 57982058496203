import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";

import useConsult from "../Firebase/Hooks/useConsult";

import Especialidades from "../Components/Especialidades";
import Testimonios from "../Components/Testimonios";
import Equipo from "../Components/Equipo";
import Galerias from "../Components/Galerias";
import NosostrosColapsible from "../Components/NosostrosColapsible";
import DerivacionesFormulario from "../Components/DerivacionesFormulario";

import img1 from "../Images/1.png";
import img1_1 from "../Images/1-1.png";
import img2 from "../Images/2.png";
import img3 from "../Images/3.png";

import galeria1 from "../Images/galeria/1.png";
import galeria2 from "../Images/galeria/2.png";
import galeria3 from "../Images/galeria/3.png";
import galeria4 from "../Images/galeria/4.png";
import galeria5 from "../Images/galeria/5.png";
import galeria6 from "../Images/galeria/6.png";
import Contactos from "../Components/Contactos";

const Index = () => {
  const {
    collection: { especialidades, testimonios, empleados },
  } = useSelector((state) => state.firestore);

  useConsult([
    {
      consulta: "obtenerRealTime",
      collectionName: "especialidades",
    },
    {
      consulta: "obtenerRealTime",
      collectionName: "testimonios",
    },
    {
      consulta: "obtenerRealTime",
      collectionName: "empleados",
    },
  ]);

  const images = [galeria1, galeria2, galeria3, galeria4, galeria5, galeria6];

  return (
    <Fragment>
      {window.innerWidth <= 990 ? (
        <img className="imagen_sobrepuesta" src={img1_1} alt="Africare" />
      ) : (
        <img className="imagen_sobrepuesta" src={img1} alt="Africare" />
      )}
      <Container maxWidth="lg" style={{ marginTop: "-75px" }}>
        <p className="titulo-1">NUESTROS SERVICIOS</p>
        <p className="titulo-2">Especialidades</p>

        <div className="contenedor-1" style={{ marginTop: "50px" }}>
          {especialidades &&
            especialidades.docs.map((especialidad) => (
              <Especialidades data={especialidad} />
            ))}
        </div>

        <section style={{ marginTop: "30px" }}>
          <NosostrosColapsible />
        </section>

        <section className="separacion" style={{ marginTop: "20px" }}>
          <p className="titulo-1">NUESTROS CONSENTIDOS</p>
          <p className="titulo-2">Écha un vistazo</p>
          <Galerias images={images} />
        </section>
      </Container>

      <section
        className="fondo-2"
        style={{
          padding: "30px 0 50px 0",
          marginBottom: "60px",
          marginTop: "30px",
        }}
      >
        <Container
          maxWidth="sm"
          style={{ textAlign: "center", marginBottom: "70px" }}
        >
          <p className="titulo-1">NUESTRO APOYO</p>
          <p className="titulo-2">Derivaciones</p>
          <p>
            En nuestra clínica veterinaria, entendemos que cada caso es único y
            a veces pueden surgir situaciones en las que se requiere un enfoque
            especializado.
          </p>
          <p>
            Es por eso que ofrecemos un servicio de derivaciones para otros
            veterinarios que necesiten apoyo adicional en el manejo de casos
            complejos o especializados.
          </p>
          <p>
            Llena este formulario y nos pondremos en contacto contigo en breve.
          </p>
        </Container>
        {window.innerWidth <= 990 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link key="agenda una cita" className="boton-2" to="/derivaciones">
              Llenar formulario
            </Link>
          </div>
        ) : (
          <Container>
            {especialidades && (
              <DerivacionesFormulario especialidades={especialidades.docs} />
            )}
          </Container>
        )}
      </section>

      <section>
        <Container
          sx={{ textAlign: { xs: "center", sm: "center", md: "left" } }}
        >
          <p className="titulo-1" style={{ textAlign: "inherit" }}>
            NUESTROS PACIENTES
          </p>
          <h1 className="titulo-2" style={{ textAlign: "inherit" }}>
            Lo que dicen de nosotros
          </h1>
          <div className="contenedor-centro" style={{ margin: "0 20px" }}>
            {testimonios &&
              testimonios.docs.map((testimonio) => (
                <div className="item-centro">
                  <Testimonios data={testimonio} />
                </div>
              ))}
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <Link key="agenda una cita" className="boton-2" to="/testimonios">
              Ver más
            </Link>
          </div>
        </Container>
      </section>

      <section
        className="fondo-1"
        style={{
          padding: "80px 0 80px 0",
          marginBottom: "60px",
          marginTop: "30px",
        }}
      >
        <Container
          sx={{
            textAlign: { xs: "center", sm: "center", md: "left" },
            width: { xs: "80%", sm: "80%" },
          }}
        >
          <Grid container spacing={5}>
            <Grid sm={12} md={6} lg={6}>
              <img src={img2} alt="HerGon" style={{ width: "100%" }} />
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <div>
                <h3 className="titulo-4" style={{ marginTop: "0" }}>
                  Asociaciones
                </h3>
                <img src={img3} alt="HerGon" style={{ width: "100%" }} />
                <p className="texto-2">
                  Somos una clínica veterinaria de vanguardia dedicada{" "}
                  <br className="movil-none" /> a cuidar de tus queridas
                  mascotas como si fueran <br className="movil-none" /> parte de
                  nuestra propia familia.
                </p>
              </div>
              <div className="centar-movil">
                <Link
                  key="agenda una cita"
                  style={{
                    textDecoration: "none",
                    fontSize: "15px",
                    padding: "14px 25px",
                  }}
                  className="boton-2"
                  to="/asociaciones"
                >
                  Ver más
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section style={{ marginTop: "50px" }}>
        <Container>
          <p className="titulo-1">NUESTROS EXPERTOS</p>
          <h1 className="titulo-2">Conoce a nuestro equipo</h1>
          <Grid container spacing={2}>
            {empleados &&
              empleados.docs.map((equipo, index) => (
                <Grid xs={6} sm={6} md={3}>
                  <Equipo data={equipo} index={index} />
                </Grid>
              ))}
          </Grid>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <Link
              key="agenda una cita"
              style={{
                textDecoration: "none",
                fontSize: "15px",
                padding: "14px 25px",
              }}
              className="boton-2"
              to="/nosotros"
            >
              Ver más
            </Link>
          </div>
        </Container>
      </section>

      <section
        className="fondo-1"
        style={{ padding: "1px 0 70px 0", marginTop: "50px" }}
      >
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <p className="titulo-1">NUESTRO CONTACTO</p>
          <p className="titulo-2">¡Agenda una cita!</p>
          <p className="texto-2" style={{ marginBottom: "50px" }}>
            Estaremos encantados de poder recibirte.{" "}
          </p>

          <Contactos />
        </div>
      </section>
    </Fragment>
  );
};

export default Index;
