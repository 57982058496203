import { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  limit,
  orderBy,
} from "firebase/firestore";

import { getCollectionRealTime } from "../Redux/Features/Firestore/firestoreSlice";

import firebase from "../firebase";
import { useDispatch } from "react-redux";
const db = firebase.firestore;

const useFirebase = ({
  consulta,
  collectionName,
  condicionWhere = false,
  condicionalLimit = false,
  storeAs = false,
  condicionalOrderBy = false,
}) => {
  const dispatch = useDispatch();
  const [result, setResult] = useState(false);

  const obtenerRealTime = async () => {
    let parametrosQuery = {};
    console.log("%c ------ realtime -------", "color: #ff1919");
    try {
      // evaluamos si tiene condicional where para estructurarla
      if (condicionWhere)
        parametrosQuery.where = where(
          condicionWhere[0],
          condicionWhere[1],
          condicionWhere[2]
        );

      // evaluamos si tiene limite de consulta para estructurarlo
      if (condicionalLimit) parametrosQuery.limit = limit(condicionalLimit);

      // evaluamos si se le mando un formato de orden
      if (condicionalOrderBy)
        parametrosQuery.order = orderBy(
          condicionalOrderBy[0],
          condicionalOrderBy[1]
        );

      let q = query(
        collection(db, collectionName),
        ...Object.values(parametrosQuery)
      );

      onSnapshot(
        q,
        async (querySnapshot) => {
          let docs = [];
          console.log({ querySnapshot });
          querySnapshot.forEach(async (doc) => {
            docs.push({ ...doc.data(), id: doc.id });

            if (docs.length === querySnapshot.size) {
              console.log({ docs });
              let res = await dispatch(
                getCollectionRealTime({
                  docs,
                  collectionName,
                  condicionWhere,
                  storeAs,
                })
              );

              setResult({
                success: true,
                res,
              });
            }
          });

          if (querySnapshot.size === 0) {
            let res = await dispatch(
              getCollectionRealTime({
                docs,
                collectionName,
                condicionWhere,
                storeAs,
              })
            );

            setResult({
              success: true,
              res,
            });
          }
        },
        (error) => {
          console.log({ error });
          setResult({
            success: false,
          });
        }
      );
    } catch (error) {
      console.log({ error });
      setResult({ success: false, error });
    }
  };

  useEffect(() => {
    console.log("%c" + consulta, "color: #ff1919");

    switch (consulta) {
      case "obtenerRealTime":
        obtenerRealTime();
        break;
      case "reset":
        setResult(false);
        break;
      default:
        console.error("Error en opcion seleccionada");
        break;
    }
    // eslint-disable-next-line
  }, [collectionName]);

  return [result];
};

export default useFirebase;
